<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('general.client_users')">
            <basic-table :columns="columns" :data="clientUsers" v-slot="props">
                <span v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    <b-button variant="warning" class="mr-1" @click="$refs.modal.open(clientUsers[props.row.originalIndex])"><feather-icon icon="EditIcon"/></b-button>
                    <b-button variant="danger" class="" @click="remove(clientUsers[props.row.originalIndex].id)"><feather-icon icon="Trash2Icon"/></b-button>
                </span>
                <span v-else-if="props.column.displayType === 1">
                    <span v-if="props.row.client_ids && clients && clients.length > 0">
                        {{
                            clients.filter(ele => props.row.client_ids.includes(ele.id) ).map(function(item) {
                                return item.name;
                            }).join(", ")
                        }}
                    </span>
                    <span v-else>/</span>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>
        </b-card>
        <AddClientUser ref="modal" :clients="clients" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import BasicTable from '@/views/components/BasicTable'
    import AddClientUser from '@/views/ClientUser/AddClientUser.vue'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import {captureException} from '@sentry/vue'

    export default {
        components: {BasicTable, AddClientUser, BCard, BOverlay, BButton},
        data() {
            return {
                clients: [],
                clientUsers: [],
                columns: [
                    {
                        label: this.$t('user.name'),
                        field: 'name',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: this.$t('user.email'),
                        field: 'email',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: this.$t('table.options'),
                        field: 'options',
                        displayType: 2,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    }
                ],
                showLoader: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true
                    const responseUsers = this.$http.get('/api/reseller/v1/users/')
                    const responseClient = this.$http.get('/api/reseller/v1/client/')

                    const responses = await Promise.all([responseUsers, responseClient])
                    this.clientUsers = responses[0].data ?? []
                    this.clients = responses[1].data ?? []
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('error.on_load_data'))
                } finally {
                    this.showLoader = false
                }

            },
            async remove(id) {
                const confirmDelete = await this.$confirmDialog(this.$t('table.delete_row_message'))
                if (!confirmDelete) return

                try {
                    this.showLoader = true
                    await this.$http.delete(`api/reseller/v1/users/${id}`)
                    this.$printSuccess(this.$t('success.on_delete'))
                    await this.loadData()
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('error.on_delete'))
                } finally {
                    this.showLoader = false
                }
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style scoped>

</style>
