var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.$t('user.user'),"size":"lg","no-close-on-backdrop":"","hide-header-close":_vm.loading,"no-close-on-esc":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":_vm.$t('user.name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.object.name),callback:function ($$v) {_vm.$set(_vm.object, "name", $$v)},expression:"object.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('user.email')}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"email","disabled":_vm.disabledEmail},model:{value:(_vm.object.email),callback:function ($$v) {_vm.$set(_vm.object, "email", $$v)},expression:"object.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.editing)?_c('b-form-group',{attrs:{"label":_vm.$t('login.password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('login.password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"new-password","placeholder":_vm.$t('user.edit_password_text'),"type":"password"},model:{value:(_vm.object.password),callback:function ($$v) {_vm.$set(_vm.object, "password", $$v)},expression:"object.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2153285085)})],1):_c('b-form-group',{attrs:{"label":_vm.$t('login.password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('login.password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"new-password","type":"password"},model:{value:(_vm.object.password),callback:function ($$v) {_vm.$set(_vm.object, "password", $$v)},expression:"object.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.editing)?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('user.user_added_on_clients'))+" ")]),_c('basic-table',{attrs:{"columns":_vm.columns,"pageLength":8,"lengths":['8'],"data":_vm.client_ids},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.column.field === 'id')?_c('span',[_vm._v(" "+_vm._s(_vm.getClientName(props.row.id))+" ")]):(props.column.field === 'actions')?_c('span',{staticClass:"d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.remove(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1):_vm._e()]}}],null,false,2189447588)})],1):_vm._e()],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.edit}},[_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.edit')))])],1),(_vm.loading)?_c('b-spinner',{staticClass:"ml-1",staticStyle:{"height":"15px","width":"15px"}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }